<template>
   <div class="card mb-1">
      <div class="card-body p-2 pb-0">
         <div class="row m-0">
            <div class="col px-0">
               <div class="row m-0">
                  <div class="col-sm-6 col-md-4 px-1 mb-15">
                     <label>Equipamento</label>
                     <select class="form-control" v-model="equipamento.nome">
                        <option :value="null">Nenhum</option>
                        <option v-for="(option, index) in equipamentos" :key="index" :value="option.nome">{{ option.nome }}</option>
                     </select>
                  </div>
                  <div class="col-6 col-md-2 px-1 mb-15">
                     <label>Fator de Pico</label>
                     <input type="text" class="form-control" v-model="equipamento.fatorPico" maxlength="200" @change="calcularConsumo" />
                  </div>
                  <div class="col-6 col-md-3 px-1 mb-15">
                     <label>Quantidade</label>
                     <input type="text" class="form-control" v-model="equipamento.quantidade" maxlength="200" @change="calcularConsumo" />
                  </div>
                  <div class="col-sm-6 col-md-3 px-1 mb-15" v-if="equipamento.potenciaBTU">
                     <label>Potência Nominal (BTU)</label>
                     <input type="text" class="form-control" v-model="equipamento.potenciaBTU" maxlength="200" @change="calcularConsumo" />
                  </div>
                  <div class="col-sm-6 col-md-3 px-1 mb-15" v-else>
                     <label>Potência Nominal (W)</label>
                     <input type="text" class="form-control" v-model="equipamento.potenciaNominal" maxlength="200" @change="calcularConsumo" />
                  </div>
               </div>
            </div>
            <div v-if="index != 0" class="w-max-content ps-1 pe-0 pt-2 align-self-center">
               <i class="far fa-trash text-red cursor-pointer" @click="remover"></i>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
   name: 'Equipamento',
   props: ['equipamento', 'index', 'equipamentos'],
   watch: {
      'equipamento.nome': function (newVal) {
         const equip = this.equipamentos.find(e => e.nome == newVal)

         if (equip) {
            this.equipamento.fatorPico = equip.fatorPico
            this.equipamento.quantidade = equip.quantidade
            this.equipamento.potenciaBTU = equip.potenciaBTU
            this.equipamento.consumoTotal = equip.consumoTotal
            this.equipamento.potenciaNominal = equip.potenciaNominal
            this.equipamento.consumoAcionamento = equip.consumoAcionamento
         } else {
            this.equipamento.fatorPico = 1
            this.equipamento.quantidade = 1
            this.equipamento.potenciaBTU = null
            this.equipamento.consumoTotal = 0
            this.equipamento.potenciaNominal = 0
            this.equipamento.consumoAcionamento = 0
         }
      }
   },
   methods: {
      calcularConsumo: function () {
         if (this.equipamento.potenciaBTU == null && /^[0-9.]+$/.test(this.equipamento.potenciaBTU)) {
            this.equipamento.potenciaNominal = parseFloat(this.equipamento.potenciaBTU) * 0.29
         }

         if (/^[0-9.]+$/.test(this.equipamento.potenciaNominal) && /^[0-9.]+$/.test(this.equipamento.quantidade) && /^[0-9.]+$/.test(this.equipamento.fatorPico)) {
            const fatorPico = parseFloat(this.equipamento.fatorPico)
            const quantidade = parseFloat(this.equipamento.quantidade)
            const potenciaNominal = parseFloat(this.equipamento.potenciaNominal)

            this.equipamento.consumoTotal = potenciaNominal * quantidade
            this.equipamento.consumoAcionamento = fatorPico * this.equipamento.consumoTotal
         }
      },
      remover: function () {
         this.$emit('remover', this.index)
      }
   }
}

</script>