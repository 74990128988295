<template>
   <div class="card mb-1">
      <div class="card-body p-2 pb-0">
         <div class="row m-0">
            <div class="col px-0">
               <div class="row m-0">
                  <div class="col-6 col-md-2 px-1 mb-15">
                     <label>Potência (CV)</label>
                     <input type="text" class="form-control" v-model="motor.potencia" maxlength="200" @change="calcularPotencia" />
                  </div>
                  <div class="col-6 col-md-3 px-1 mb-15">
                     <label>Rendimento (<span class="text-lowercase weight-600">η</span>)</label>
                     <input type="text" class="form-control" v-model="motor.rendimento" maxlength="200" @change="calcularPotencia" />
                  </div>
                  <div class="col-sm-6 col-md-4 px-1 mb-15">
                     <label>Fator de Potência (cosᵠ)</label>
                     <input type="text" class="form-control" v-model="motor.fatorPotencia" maxlength="200" @change="calcularPotencia" />
                  </div>
                  <div class="col-sm-6 col-md-3 px-1 mb-15">
                     <label>Pico partida (IP/IN)</label>
                     <input type="text" class="form-control" v-model="motor.picoPartida" maxlength="200" @change="calcularPotencia" />
                  </div>
               </div>
            </div>
            <div v-if="index != 0" class="w-max-content ps-1 pe-0 pt-2 align-self-center">
               <i class="far fa-trash text-red cursor-pointer" @click="remover"></i>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
   name: 'MotorEletrico',
   props: ['motor', 'index'],
   methods: {
      calcularPotencia: function () {
         if (/^[0-9.]+$/.test(this.motor.potencia) && /^[0-9.]+$/.test(this.motor.rendimento) || /^[0-9.]+$/.test(this.motor.fatorPotencia) && /^[0-9.]+$/.test(this.motor.picoPartida)) {
            const potencia = parseFloat(this.motor.potencia)
            const rendimento = parseFloat(this.motor.rendimento)
            const picoPartida = parseFloat(this.motor.picoPartida)
            const fatorPotencia = parseFloat(this.motor.fatorPotencia)
            const fatorConversao = parseFloat(this.motor.fatorConversao)
            
            this.motor.potenciaTotal = (((fatorConversao * potencia) / (rendimento / 100)) / fatorPotencia) * (picoPartida / 2)
         }
      },
      remover: function () {
         this.$emit('remover', this.index)
      }
   }
}

</script>