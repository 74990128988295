<template>
   <div class="card mb-1">
      <div class="card-body p-2 pb-0">
         <div class="row m-0">
            <div class="col px-0">
               <div class="row m-0">
                  <div class="col-sm-6 px-1 mb-15">
                     <label>Tensão de alimentação (V)</label>
                     <input type="text" class="form-control" v-model="solda.tensaoAlimentacao" maxlength="200" @change="calcularPotencia" />
                  </div>
                  <div class="col-sm-6 px-1 mb-15">
                     <label>Corrente nominal máxima (A)</label>
                     <input type="text" class="form-control" v-model="solda.correnteNominal" maxlength="200" @change="calcularPotencia" />
                  </div>
               </div>
            </div>
            <div v-if="index != 0" class="w-max-content ps-1 pe-0 pt-2 align-self-center">
               <i class="far fa-trash text-red cursor-pointer" @click="remover"></i>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
   name: 'MaquinaSolda',
   props: ['solda', 'index'],
   methods: {
      calcularPotencia: function () {
         if (/^[0-9.]+$/.test(this.solda.tensaoAlimentacao) && /^[0-9.]+$/.test(this.solda.correnteNominal)) {
            const tensao = parseFloat(this.solda.tensaoAlimentacao)
            const corrente = parseFloat(this.solda.correnteNominal)
            this.solda.potenciaTotal = (tensao * corrente) * 1.5
         }
      },
      remover: function () {
         this.$emit('remover', this.index)
      }
   }
}

</script>