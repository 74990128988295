<template>
   <main>
      <!-- breadcrumb area start -->
      <section class="breadcrumb__area pt-30 grey-bg">
         <div class="container">
            <div class="row">
               <div :class="resultado.codigo ? 'col-12' : 'col-lg-8 offset-lg-2'">
                  <div class="breadcrumb__wrapper">
                     <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0">
                           <li class="breadcrumb-item"><router-link to="/"><i class="far fa-home color-theme me-2"></i>Home</router-link></li>
                           <li class="breadcrumb-item active" aria-current="page">Calculadora</li>
                        </ol>
                     </nav>
                  </div>
               </div>
            </div>
         </div>
      </section>

		<!-- selecione produto area start -->
      <section v-if="calculadora.tipo == null" class="selecione__produto__area pt-20 pb-200 grey-bg">
         <div class="container">
            <div class="row">
               <div class="col-lg-8 offset-lg-2 container-formulario">
                  <div class="row m-0">
                     <div class="col-12 px-1">
                        <div class="section__head mb-30">
                           <div class="w-100 section__title">
                              <h3 class="text-center mb-1" v-html="sourceCalculadora.tituloPrincipal"></h3>
                           </div>
                        </div>
                     </div>
                     <div class="col-12 px-1 mb-20">
                        <p class="text-center" v-html="sourceCalculadora.descricaoPrincipal"></p>
                     </div>
                     
                     <div class="col-6 col-sm-4 px-1 mb-1">
                        <div class="card h-100 cursor-pointer" @click="setTipoProduto('GERADORES')">
                           <div class="card-body px-1">
                              <div class="blog__thumb fix px-3">
                                 <a href="javascript:;"><img src="@/assets/cliente/img/icon/gerador.jpeg" alt="icon" @error="imageError"></a>
                              </div>
                              <div class="blog__content pt-12 px-0 pb-0">
                                 <h3 class="limitador text-center font-13 mb-0">GERADORES</h3>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-6 col-sm-4 px-1 mb-1">
                        <div class="card h-100 cursor-pointer" @click="setTipoProduto('MOTOBOMBAS')">
                           <div class="card-body px-1">
                              <div class="blog__thumb fix px-3">
                                 <a href="javascript:;"><img src="@/assets/cliente/img/icon/motobomba.jpeg" alt="icon" @error="imageError"></a>
                              </div>
                              <div class="blog__content pt-12 px-0 pb-0">
                                 <h3 class="limitador text-center font-13 mb-0">MOTOBOMBAS</h3>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-sm-4 px-1 mb-1">
                        <div class="card h-100 cursor-pointer" @click="setTipoProduto('CORTADORES DE GRAMA')">
                           <div class="card-body px-1 d-flex d-sm-block align-items-center">
                              <div class="col-4 col-sm-12">
                                 <div class="blog__thumb fix px-3">
                                    <a href="javascript:;"><img src="@/assets/cliente/img/icon/cortadorGrama.jpeg" alt="icon" @error="imageError"></a>
                                 </div>
                              </div>
                              <div class="col-8 col-sm-12">
                                 <div class="blog__content pt-12 px-0 pb-0">
                                    <h3 class="limitador-2 text-center font-13 mb-0">CORTADORES DE GRAMA</h3>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>

      <section v-else-if="resultado.codigo == null" class="calculadora__area pt-20 pb-200 grey-bg">
         <div class="container">
            <div class="row">
               <div class="col-lg-8 offset-lg-2 container-formulario">
                  <div class="row m-0" v-if="calculadora.tipo == 'MOTOBOMBAS'">
                     <div class="col-12 px-1">
                        <div class="section__head mb-20">
                           <div class="row section__title m-0">
                              <div class="wpx-55 d-block d-xxl-none"></div><div class="wpx-100 d-none d-xxl-block"></div>
                              <div class="col px-1 pb-1 align-self-end">
                                 <h3 class="text-center mb-1" v-html="sourceCalculadora.tituloMotobomba"></h3>
                              </div>
                              <div class="w-max-content px-1 text-end align-self-center">
                                 <a href="javascript:;" class="t-y-btn t-y-btn-2" @click="resetCalculo">
                                    <i class="far fa-undo font-12"></i><span class="d-none d-xxl-inline ms-1"> Voltar</span>
                                 </a>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-12 px-1 mb-20">
                        <p class="text-center" v-html="sourceCalculadora.descricaoMotobomba"></p>
                     </div>
                     <div class="col-12 px-1">
                        <div class="accordion" id="motobombas">
                           <div class="accordion-item mb-2">
                              <h2 class="accordion-header" id="headMotobombas1">
                                 <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseMotobombas1" aria-expanded="true" aria-controls="collapseMotobombas1">
                                    <small>1</small><span>Seus dados</span>
                                 </button>
                              </h2>
                              <div id="collapseMotobombas1" class="accordion-collapse collapse show" aria-labelledby="headMotobombas1">
                                 <div class="accordion-body pb-2">
                                    <div class="row m-0">
                                       <div class="col-sm-4 px-1 mb-15">
                                          <label>Nome</label>
                                          <input type="text" class="form-control" v-model="calculadora.cliente.nome" maxlength="200" />
                                       </div>
                                       <div class="col-sm-4 px-1 mb-15">
                                          <label>Email</label>
                                          <input type="text" class="form-control" v-model="calculadora.cliente.email" maxlength="200" />
                                       </div>
                                       <div class="col-sm-4 px-1 mb-15">
                                          <label>Telefone</label>
                                          <input type="text" class="form-control" v-model="calculadora.cliente.telefone" maxlength="200" />
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           
                           <div class="accordion-item mb-2">
                              <h2 class="accordion-header" id="headMotobombas2">
                                 <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseMotobombas2" aria-expanded="false" aria-controls="collapseMotobombas2">
                                    <small>2</small><span>Sobre o projeto</span>
                                 </button>
                              </h2>
                              <div id="collapseMotobombas2" class="accordion-collapse collapse" aria-labelledby="headMotobombas2">
                                 <div class="accordion-body pb-2">
                                    <div class="row m-0">
                                       <div class="col-sm-6 px-1 mb-15">
                                          <label>Altura de sucção (metros)</label>
                                          <input type="text" class="form-control" v-model="calculadora.motobomba.alturaSuccao" maxlength="200" @change="calcularMCA" />
                                       </div>
                                       <div class="col-sm-6 px-1 mb-15">
                                          <label>Altura de recalque (metros)</label>
                                          <input type="text" class="form-control" v-model="calculadora.motobomba.alturaRecalque" maxlength="200" @change="calcularMCA" />
                                       </div>
                                       <div class="col-sm-7 col-md-6 px-1 mb-15">
                                          <label>Comprimento da tubulação (metros)</label>
                                          <input type="text" class="form-control" v-model="calculadora.motobomba.comprimentoTubulacao" maxlength="200" @change="calcularPerdaCarga" />
                                       </div>
                                       <div class="col-sm-5 col-md-6 px-1 mb-15">
                                          <label>Vazão máxima (L/h)</label>
                                          <input type="text" class="form-control" v-model="calculadora.motobomba.vazaoMaxima" maxlength="200" />
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           
                           <div class="accordion-item">
                              <h2 class="accordion-header" id="headMotobombas4">
                                 <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseMotobombas4" aria-expanded="false" aria-controls="collapseMotobombas4">
                                    <small>3</small><span>Cálculo</span>
                                 </button>
                              </h2>
                              <div id="collapseMotobombas4" class="accordion-collapse collapse" aria-labelledby="headMotobombas4">
                                 <div class="accordion-body pb-2">
                                    <div class="row m-0">
                                       <div class="col-6 col-md-4 col-xxl-2 px-1 mb-15">
                                          <label>Combustível</label>
                                          <select class="form-control" v-model="calculadora.motobomba.combustivel">
                                             <option v-for="(option, index) in ['Gasolina', 'Diesel']" :key="index" :value="option">{{ option }}</option>
                                          </select>
                                       </div>
                                       <div class="col-6 col-md-4 col-xxl-2 px-1 mb-15">
                                          <label>MCA Calculado</label>
                                          <input type="text" class="form-control" v-model="calculadora.motobomba.mca" maxlength="200" readonly />
                                       </div>
                                       <div class="col-sm-6 col-md-4 col-xxl-3 px-1 mb-15">
                                          <label>Fator de perda de carga</label>
                                          <input type="text" class="form-control" v-model="calculadora.motobomba.perdaCarga" maxlength="200" readonly />
                                       </div>
                                       <div class="col-sm-6 col-md-5 col-xxl-3 px-1 mb-15">
                                          <label>MCA c/ margem segurança</label>
                                          <input type="text" class="form-control" v-model="calculadora.motobomba.mcaSeguranca" maxlength="200" readonly />
                                       </div>
                                       <div class="col-md-7 col-xxl-2 px-1 mb-15 text-end align-self-end">
                                          <button class="t-y-btn t-y-btn-2 w-100 px-0" @click="buscarResultado">Ver resultado <i class="fas fa-chevron-right font-12 ms-2"></i></button>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="row m-0" v-else-if="calculadora.tipo == 'CORTADORES DE GRAMA'">
                     <div class="col-12 px-1">
                        <div class="section__head mb-20">
                           <div class="row section__title m-0">
                              <div class="wpx-55 d-block d-xxl-none"></div><div class="wpx-100 d-none d-xxl-block"></div>
                              <div class="col px-1 pb-1 align-self-end">
                                 <h3 class="text-center mb-1" v-html="sourceCalculadora.tituloCortadoresGrama"></h3>
                              </div>
                              <div class="w-max-content px-1 text-end align-self-center">
                                 <a href="javascript:;" class="t-y-btn t-y-btn-2" @click="resetCalculo">
                                    <i class="far fa-undo font-12"></i><span class="d-none d-xxl-inline ms-1"> Voltar</span>
                                 </a>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-12 px-1 mb-20">
                        <p class="text-center" v-html="sourceCalculadora.descricaoCortadoresGrama"></p>
                     </div>
                     <div class="col-12 px-1">
                        <div class="accordion" id="cortadorGrama">
                           <div class="accordion-item mb-2">
                              <h2 class="accordion-header" id="headCortadorGrama1">
                                 <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseCortadorGrama1" aria-expanded="true" aria-controls="collapseCortadorGrama1">
                                    <small>1</small><span>Seus dados</span>
                                 </button>
                              </h2>
                              <div id="collapseCortadorGrama1" class="accordion-collapse collapse show" aria-labelledby="headCortadorGrama1">
                                 <div class="accordion-body pb-2">
                                    <div class="row m-0">
                                       <div class="col-sm-4 px-1 mb-15">
                                          <label>Nome</label>
                                          <input type="text" class="form-control" v-model="calculadora.cliente.nome" maxlength="200" />
                                       </div>
                                       <div class="col-sm-4 px-1 mb-15">
                                          <label>Email</label>
                                          <input type="text" class="form-control" v-model="calculadora.cliente.email" maxlength="200" />
                                       </div>
                                       <div class="col-sm-4 px-1 mb-15">
                                          <label>Telefone</label>
                                          <input type="text" class="form-control" v-model="calculadora.cliente.telefone" maxlength="200" />
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           
                           <div class="accordion-item mb-2">
                              <h2 class="accordion-header" id="headCortadorGrama2">
                                 <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseCortadorGrama2" aria-expanded="false" aria-controls="collapseCortadorGrama2">
                                    <small>2</small><span>Sobre o projeto</span>
                                 </button>
                              </h2>
                              <div id="collapseCortadorGrama2" class="accordion-collapse collapse" aria-labelledby="headCortadorGrama2">
                                 <div class="accordion-body pb-2">
                                    <div class="row m-0">
                                       <div class="col-sm-6 px-1 mb-15">
                                          <label>Área de corte (m²)</label>
                                          <input type="text" class="form-control" v-model="calculadora.cortadorGrama.areaCorte" maxlength="200" @change="calcularProdutividade" />
                                       </div>
                                       <div class="col-sm-6 px-1 mb-15">
                                          <label>Tempo esperado (minutos)</label>
                                          <input type="text" class="form-control" v-model="calculadora.cortadorGrama.tempoEsperado" maxlength="200" @change="calcularProdutividade" />
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           
                           <div class="accordion-item">
                              <h2 class="accordion-header" id="headCortadorGrama4">
                                 <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseCortadorGrama4" aria-expanded="false" aria-controls="collapseCortadorGrama4">
                                    <small>3</small><span>Cálculo</span>
                                 </button>
                              </h2>
                              <div id="collapseCortadorGrama4" class="accordion-collapse collapse" aria-labelledby="headCortadorGrama4">
                                 <div class="accordion-body pb-2">
                                    <div class="row m-0">
                                       <div class="col-sm-7 col-xxl-9 px-1 mb-15">
                                          <label>Produtividade requerida</label>
                                          <input type="text" class="form-control" v-model="calculadora.cortadorGrama.produtividade" maxlength="200" readonly />
                                       </div>
                                       <div class="col-sm-5 col-xxl-3 px-1 mb-15 text-end align-self-end">
                                          <button class="t-y-btn t-y-btn-2 w-100" @click="buscarResultado">Ver resultado <i class="fas fa-chevron-right font-12 ms-2"></i></button>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="row m-0" v-else-if="calculadora.tipo == 'GERADORES'">
                     <div class="col-12 px-1">
                        <div class="section__head mb-20">
                           <div class="row section__title m-0">
                              <div class="wpx-55 d-block d-xxl-none"></div><div class="wpx-100 d-none d-xxl-block"></div>
                              <div class="col px-1 pb-1 align-self-end">
                                 <h3 class="text-center mb-1" v-html="sourceCalculadora.tituloGeradores"></h3>
                              </div>
                              <div class="w-max-content px-1 text-end align-self-center">
                                 <a href="javascript:;" class="t-y-btn t-y-btn-2" @click="resetCalculo">
                                    <i class="far fa-undo font-12"></i><span class="d-none d-xxl-inline ms-1"> Voltar</span>
                                 </a>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-12 px-1 mb-20">
                        <p class="text-center" v-html="sourceCalculadora.descricaoGeradores"></p>
                     </div>
                     <div class="col-12 px-1">
                        <div class="accordion" id="geradores">
                           <div class="accordion-item mb-2">
                              <h2 class="accordion-header" id="headGeradores1">
                                 <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseGeradores1" aria-expanded="true" aria-controls="collapseGeradores1">
                                    <small>1</small><span>Seus dados</span>
                                 </button>
                              </h2>
                              <div id="collapseGeradores1" class="accordion-collapse collapse show" aria-labelledby="headGeradores1">
                                 <div class="accordion-body pb-2">
                                    <div class="row m-0">
                                       <div class="col-sm-4 px-1 mb-15">
                                          <label>Nome</label>
                                          <input type="text" class="form-control" v-model="calculadora.cliente.nome" maxlength="200" />
                                       </div>
                                       <div class="col-sm-4 px-1 mb-15">
                                          <label>Email</label>
                                          <input type="text" class="form-control" v-model="calculadora.cliente.email" maxlength="200" />
                                       </div>
                                       <div class="col-sm-4 px-1 mb-15">
                                          <label>Telefone</label>
                                          <input type="text" class="form-control" v-model="calculadora.cliente.telefone" maxlength="200" />
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           
                           <div class="accordion-item mb-2">
                              <h2 class="accordion-header" id="headGeradores2">
                                 <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseGeradores2" aria-expanded="false" aria-controls="collapseGeradores2">
                                    <small>2</small><span>Possui motor elétrico na instalação?</span>
                                 </button>
                              </h2>
                              <div id="collapseGeradores2" class="accordion-collapse collapse" aria-labelledby="headGeradores2">
                                 <div class="accordion-body">
                                    <div class="row m-0">
                                       <div class="col-12 px-1">
                                          <button class="btn font-13 me-1" :class="calculadora.gerador.motorEletrico ? 'btn-primary' : 'btn-outline-primary'" @click="calculadora.gerador.motorEletrico = true;">Sim</button>
                                          <button class="btn font-13" :class="calculadora.gerador.motorEletrico ? 'btn-outline-primary' : 'btn-primary'" @click="calculadora.gerador.motorEletrico = false;">Não</button>
                                          <hr v-if="calculadora.gerador.motorEletrico">
                                       </div>
                                       <div v-if="calculadora.gerador.motorEletrico" class="col-12 px-1 mb-10">
                                          <h1 class="font-15 text-center mb-15">Inclua quantos motores elétricos serão necessários</h1>
                                          <motorEletrico v-for="(motor, index) in calculadora.gerador.motoresEletricos" :key="index" :motor="motor" :index="index" 
                                             @remover="removerMotor($event)" />
                                       </div>
                                       <div v-if="calculadora.gerador.motorEletrico" class="col-12 px-1 text-center">
                                          <button class="t-y-btn t-y-btn-2" @click="addMotor">Incluir <i class="fas fa-plus font-12 ms-2"></i></button>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           
                           <div class="accordion-item mb-2">
                              <h2 class="accordion-header" id="headGeradores3">
                                 <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseGeradores3" aria-expanded="false" aria-controls="collapseGeradores3">
                                    <small>3</small><span>Equipamentos que estarão ligados no gerador</span>
                                 </button>
                              </h2>
                              <div id="collapseGeradores3" class="accordion-collapse collapse" aria-labelledby="headGeradores3">
                                 <div class="accordion-body pb-2">
                                    <div class="row m-0">
                                       <div class="col-12 px-1 mb-10">
                                          <equipamento v-for="(equipamento, index) in calculadora.gerador.equipamentos" :key="index" :equipamento="equipamento" :index="index" :equipamentos="equipamentos"
                                             @remover="removerEquipamento($event)" />
                                       </div>
                                       <div class="col-12 px-1 text-center">
                                          <button class="t-y-btn t-y-btn-2" @click="addEquipamento">Incluir <i class="fas fa-plus font-12 ms-2"></i></button>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>

                           <div class="accordion-item mb-2">
                              <h2 class="accordion-header" id="headGeradores4">
                                 <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseGeradores4" aria-expanded="false" aria-controls="collapseGeradores4">
                                    <small>4</small><span>Máquinas de solda ligadas ao gerador</span>
                                 </button>
                              </h2>
                              <div id="collapseGeradores4" class="accordion-collapse collapse" aria-labelledby="headGeradores4">
                                 <div class="accordion-body">
                                    <div class="row m-0">
                                       <div class="col-12 px-1">
                                          <button class="btn font-13 me-1" :class="calculadora.gerador.maquinaSolda ? 'btn-primary' : 'btn-outline-primary'" @click="calculadora.gerador.maquinaSolda = true;">Sim</button>
                                          <button class="btn font-13" :class="calculadora.gerador.maquinaSolda ? 'btn-outline-primary' : 'btn-primary'" @click="calculadora.gerador.maquinaSolda = false;">Não</button>
                                          <hr v-if="calculadora.gerador.maquinaSolda">
                                       </div>
                                       <div v-if="calculadora.gerador.maquinaSolda" class="col-12 px-1 mb-10">
                                          <maquinaSolda v-for="(solda, index) in calculadora.gerador.maquinasSolda" :key="index" :solda="solda" :index="index" 
                                             @remover="removerMaquinaSolda($event)" />
                                       </div>
                                       <div v-if="calculadora.gerador.maquinaSolda" class="col-12 px-1 text-center">
                                          <button class="t-y-btn t-y-btn-2" @click="addMaquinaSolda">Incluir <i class="fas fa-plus font-12 ms-2"></i></button>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           
                           <div class="accordion-item">
                              <h2 class="accordion-header" id="headGeradores5">
                                 <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseGeradores5" aria-expanded="false" aria-controls="collapseGeradores5">
                                    <small>5</small><span>Cálculo</span>
                                 </button>
                              </h2>
                              <div id="collapseGeradores5" class="accordion-collapse collapse" aria-labelledby="headGeradores5">
                                 <div class="accordion-body pb-2">
                                    <div class="row m-0">
                                       <div class="col-6 col-md-4 col-xxl-2 px-1 mb-15">
                                          <label>Combustível</label>
                                          <select class="form-control" v-model="calculadora.gerador.combustivel">
                                             <option v-for="(option, index) in ['Gasolina', 'Diesel']" :key="index" :value="option">{{ option }}</option>
                                          </select>
                                       </div>
                                       <div class="col-6 col-md-4 col-xxl-2 px-1 mb-15">
                                          <label>Tipo de fase</label>
                                          <select class="form-control" v-model="calculadora.gerador.tipoFase">
                                             <option v-for="(option, index) in ['Monofásico', 'Trifásico']" :key="index" :value="option">{{ option }}</option>
                                          </select>
                                       </div>
                                       <div class="col-sm-6 col-md-4 col-xxl-3 px-1 mb-15">
                                          <label>Margem de segurança (%)</label>
                                          <input type="text" class="form-control" v-model="calculadora.gerador.margemSeguranca" maxlength="200" @change="calcularPotencia" />
                                       </div>
                                       <div class="col-sm-6 col-md-6 col-xxl-3 px-1 mb-15">
                                          <label>Potência estimada</label>
                                          <input type="text" class="form-control" v-model="calculadora.gerador.potenciaEstimada" maxlength="200" readonly />
                                       </div>
                                       <div class="col-md-6 col-xxl-2 px-1 mb-15 text-end align-self-end">
                                          <button class="t-y-btn t-y-btn-2 w-100 px-0" @click="buscarResultado">Ver resultado <i class="fas fa-chevron-right font-12 ms-2"></i></button>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>

      <section v-else class="resultado__area pt-20 pb-20 grey-bg">
         <div class="container">
            <div class="row">
               <div class="col-12">
                  <div class="section__head mb-20">
                     <div class="row section__title m-0">
                        <div class="wpx-55 d-block d-xxl-none"></div><div class="wpx-100 d-none d-xxl-block"></div>
                        <div class="col px-1 pb-1 align-self-end">
                           <h3 class="text-center mb-1">Resultado</h3>
                        </div>
                        <div class="w-max-content px-1 text-end align-self-center">
                           <a href="javascript:;" class="t-y-btn t-y-btn-2" @click="resetCalculo">
                              <i class="far fa-undo font-12"></i><span class="d-none d-xxl-inline ms-1"> Voltar</span>
                           </a>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-12 mb-20">
                  <p class="text-center" v-html="sourceCalculadora.descricaoResultado"></p>
               </div>
               <div class="col-12">
                  <div class="card">
                     <div class="card-body">
                        <div class="row mx-0 mt-30 mb-40 align-items-center">
                           <div class="wpx-md-250 wpx-xl-300 px-1 mb-40 mb-md-0">
                              <img :src="resultado.foto || ''" @error="imageError" class="w-100 rounded" />
                           </div>
                           <div class="col px-1">
                              <h1 class="font-24 mb-1">{{ resultado.nome }}</h1>
                              <p class="mb-25">
                                 <span>Cód. {{ String(resultado.codigo).substring(0, 2) +"-"+ String(resultado.codigo).substring(2, String(resultado.codigo).length) }}</span>
                                 <small class="mx-3">|</small>
                                 <span>{{ resultado.nomeMarca }}</span>
                              </p>
                              <p class="font-22 mb-25">
                                 <span class="text-danger mb-0"><small>R$ </small>{{ resultado.preco == null ? '-' : parseFloat(resultado.preco).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}</span>
                              </p>
                              <p class="mb-25">{{ resultado.descricao }}</p>
                              <div class="d-flex align-items-center" v-if="logado.cliente && resultado.preco != null">
                                 <div class="w-max-content">
                                    <div class="input-group input-quantity">
                                       <button name="btn-minus" @click.stop="resultado.quantidade = resultado.quantidade - resultado.qtdMult;" :disabled="resultado.quantidade <= resultado.qtdMult">-</button>
                                       <input type="number" v-model="resultado.quantidade" @blur="resultado.quantidade = Math.ceil(resultado.quantidade / resultado.qtdMult) * resultado.qtdMult">
                                       <button name="btn-plus" @click.stop="resultado.quantidade = resultado.quantidade + resultado.qtdMult;">+</button>
                                    </div>
                                 </div>

                                 <div class="w-max-content ms-4">
                                    <button class="btn bg-theme text-white font-13" @click="addCarrinho">
                                       <i class="far fa-shopping-cart me-1"></i><span class="d-none d-sm-inline"> Adicionar ao carrinho</span>
                                    </button>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>

      <!-- relacionados area start -->
      <relacionados v-if="resultado.codigo" :array="resultado.relacionados" :titulo="'Veja outros modelos'" :classes="'relacionados__area pt-30 pb-100 grey-bg'" />
   </main>
</template>

<script>

import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import equipamento from '@/components/calculadora/Equipamento.vue'
import maquinaSolda from '@/components/calculadora/MaquinaSolda.vue'
import motorEletrico from '@/components/calculadora/MotorEletrico.vue'
import relacionados from '@/components/CarouselProdutos.vue'

export default {
	name: 'Calculadora',
   data: function() {
		return {
         equipamentos: [],
			calculadora: {
            'tipo': null, 'cliente': {'nome': null, 'email': null, 'telefone': null}, 
            'motobomba': {'combustivel': 'Gasolina', 'alturaSuccao': null, 'alturaRecalque': null, 'comprimentoTubulacao': null, 'vazaoMaxima': null, 'perdaCarga': null, 'mca':  null, 'mcaSeguranca': null},
            'cortadorGrama': {'areaCorte': null, 'tempoEsperado': null, 'produtividade': null},
            'gerador': {'motorEletrico': false, 'maquinaSolda': false, 'margemSeguranca': '20', 'potenciaEstimada': 0, 'combustivel': 'Gasolina', 'tipoFase': 'Monofásico',
               'motoresEletricos': [{'potencia': 1, 'fatorConversao': 736, 'rendimento': 0, 'fatorPotencia': 0, 'potenciaTotal': 0, 'picoPartida': 1}],
               'equipamentos': [{'nome': null, 'fatorPico': 1, 'potenciaBTU': null, 'potenciaNominal': 0, 'quantidade': 1, 'consumoTotal': 0, 'consumoAcionamento': 0}],
               'maquinasSolda': [{'tensaoAlimentacao': 220, 'correnteNominal': 0, 'potenciaTotal': 0}]
            },
            'motorEletrico': {'potencia': 1, 'fatorConversao': 736, 'rendimento': 0, 'fatorPotencia': 0, 'potenciaTotal': 0, 'picoPartida': 1},
            'equipamento': {'nome': null, 'fatorPico': 1, 'potenciaBTU': null, 'potenciaNominal': 0, 'quantidade': 1, 'consumoTotal': 0, 'consumoAcionamento': 0},
            'maquinaSolda': {'tensaoAlimentacao': 220, 'correnteNominal': 0, 'potenciaTotal': 0}
         },
         resultado: {
            "codigo": null, "qtdMult": 1, "nome": null, "descricao": null, "preco": null, "precoComImposto": null, "valorICMS": null, "valorIPI": null, "nomeMarca": null, 
            "fotoMarca": null, "relacionados": [], "quantidade": 1, "foto": null
         }
		}
	},
   computed: {
		... mapState({
         sourceCalculadora: state => state.source.calculadora,
			urlRest: state => state.urlRest,
			logado: state => state.logado
		})
	},
   watch: {
      'calculadora.gerador.maquinasSolda': {
         handler: function () {
            this.calcularPotencia()
         },
         deep: true
      },
      'calculadora.gerador.equipamentos': {
         handler: function () {
            this.calcularPotencia()
         },
         deep: true
      },
      'calculadora.gerador.motoresEletricos': {
         handler: function () {
            this.calcularPotencia()
         },
         deep: true
      }
   },
   components: {
      motorEletrico, equipamento, maquinaSolda, relacionados
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      addCarrinho : function () {
         this.$store.dispatch('addCarrinho', {'produto': JSON.parse(JSON.stringify(this.resultado)), 'selectVariaco': false })
      },
      setTipoProduto: function (tipo) {
         this.calculadora.tipo = tipo
      },
      calcularPerdaCarga: function () {
         if (/^[0-9.]+$/.test(this.calculadora.motobomba.comprimentoTubulacao)) {
            this.calculadora.motobomba.perdaCarga = parseFloat(this.calculadora.motobomba.comprimentoTubulacao) * 0.10
            this.calcularMCA()
         }
      },
      calcularMCA: function () {
         if (/^[0-9.]+$/.test(this.calculadora.motobomba.alturaSuccao) && /^[0-9.]+$/.test(this.calculadora.motobomba.alturaRecalque) && /^[0-9.]+$/.test(this.calculadora.motobomba.perdaCarga)) {
            const succao = parseFloat(this.calculadora.motobomba.alturaSuccao)
            const recalque = parseFloat(this.calculadora.motobomba.alturaRecalque)
            const perda = parseFloat(this.calculadora.motobomba.perdaCarga)
            
            this.calculadora.motobomba.mca = (succao + recalque + perda) + ((succao + recalque + perda) * 0.05)
            this.calculadora.motobomba.mcaSeguranca = Math.ceil(this.calculadora.motobomba.mca)
         }
      },
      calcularProdutividade: function () {
         if (/^[0-9.]+$/.test(this.calculadora.cortadorGrama.areaCorte) && /^[0-9.]+$/.test(this.calculadora.cortadorGrama.tempoEsperado)) {
            this.calculadora.cortadorGrama.produtividade = parseFloat(this.calculadora.cortadorGrama.areaCorte) / (parseFloat(this.calculadora.cortadorGrama.tempoEsperado) / 60)
         }
      },
      calcularPotencia: function () {
         if (/^[0-9.]+$/.test(this.calculadora.gerador.margemSeguranca)) {
            const margemSeguranca = parseFloat(this.calculadora.gerador.margemSeguranca)
            const consumoEquipamentos = this.calculadora.gerador.equipamentos.map(e => e.consumoAcionamento).reduce((total, consumo) => total += consumo, 0)
            const potenciaMotores = this.calculadora.gerador.motoresEletricos.map(e => e.potenciaTotal).reduce((total, potencia) => total += potencia, 0)
            const potenciaSoldas = this.calculadora.gerador.maquinasSolda.map(e => e.potenciaTotal).reduce((total, potencia) => total += potencia, 0)

            this.calculadora.gerador.potenciaEstimada = Math.ceil((consumoEquipamentos + potenciaMotores + potenciaSoldas) * (1 + (margemSeguranca / 100)))
         }
      },
      addMaquinaSolda: function () {
         this.calculadora.gerador.maquinasSolda.push(JSON.parse(JSON.stringify(this.calculadora.maquinaSolda)))
      },
      addMotor: function () {
         this.calculadora.gerador.motoresEletricos.push(JSON.parse(JSON.stringify(this.calculadora.motorEletrico)))
      },
      removerMaquinaSolda: function (index) {
         this.calculadora.gerador.maquinasSolda.splice(index, 1)
      },
      removerMotor: function (index) {
         this.calculadora.gerador.motoresEletricos.splice(index, 1)
      },
      addEquipamento: function () {
         this.calculadora.gerador.equipamentos.push(JSON.parse(JSON.stringify(this.calculadora.equipamento)))
      },
      removerEquipamento: function (index) {
         this.calculadora.gerador.equipamentos.splice(index, 1)
      },
      resetCalculo: function () {
         Object.assign(this.$data.resultado, this.$options.data.apply(this).resultado)
         Object.assign(this.$data.calculadora, this.$options.data.apply(this).calculadora)
      },
      buscarEquipamentos: function () {
         this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'get',
				url: this.urlRest +'site/getEquipamentosCalculadora'

			}).then(response => {
            this.equipamentos = response.data

         }).catch((error) => {
				if (error.response != undefined) {
               this.$toast.fire({
                  icon: 'error',
                  title: 'Erro: ' + error.response.status
               })
            } else {
					this.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
      },
      buscarResultado: function () {
         this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'post',
				url: this.urlRest +'site/getResultadoCalculadora',
            headers: {
               'Content-Type': 'application/json'
            },
            data: this.calculadora

			}).then(response => {
            this.resultado = response.data
            window.scrollTo(0, 0)

         }).catch((error) => {
				if (error.response != undefined) {
               if (error.response.status == 400) {
						Swal.fire({
							icon: 'error',
							title: 'Não encontrado',
							text: 'Não foi possível encontrar um produto para as especificações pedidas.',
                     confirmButtonText: 'Fechar'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
            } else {
					this.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
      }
   },
   mounted() {
      this.buscarEquipamentos()
      this.calcularProdutividade()
   }
}

</script>

<style scoped>

.selecione__produto__area .card:hover {
   border-color: var(--color-theme);
}

.selecione__produto__area .card:hover h3 {
   color: var(--color-theme);
}

.selecione__produto__area .card:hover img {
   transform: scale(1.1) rotate(1deg);
}

.accordion[id] .accordion-button small {
   font-size: 18px;
   font-weight: 600;
   margin-right: 12px;
   border-radius: 4px;
   padding: 6px 13px;
   position: absolute;
   top: 50%;
   left: 8px;
   transform: translateY(-50%);
   color: #666;
   border: 1px solid #ccc;
   background-color: #fff;
   font-family: monospace;
}

.accordion[id] .accordion-button:not(.collapsed) small {
   color: #fff;
   border: 1px solid var(--color-theme);
   background-color: var(--color-theme);
}

.accordion[id] .accordion-button span {
   margin-left: 40px;
}

.accordion[id] .accordion-button.collapsed,
.accordion[id] .accordion-collapse {
   border: 1px solid #ccc !important;
   border-radius: 3px;
}

</style>